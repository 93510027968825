import { getTimeAndMinutes } from "./formatDate";

export async function formatMessages(data) {
  let format = {};
  data.map((item) => {
    const id = item.conversation_id;
    if (!format[id]) format[id] = [];
    const element = {
      id: item.id,
      message: item.message,
      type: item.type,
      created_at: getTimeAndMinutes(item.created_at),
    };

    return format[id].push(element);
  });

  return format;
}

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};
