import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../components/img/logo.png";
import toast from "react-hot-toast";
import { useState } from "react";
import Confirm from "./confirm";
import Spinner from "../components/spinner/spinner";

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [userData, setUserData] = useState({});
  const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;

  const toConfirm = async (e) => {
    e.preventDefault();
    const username = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const password2 = e.target[3].value;
    //const checked = e.target[4].checked;

    /* if (!checked) {
      toast.error("Please accept terms and conditions");
      return;
    } */
    if (password !== password2) {
      toast.error("Password is not same!");
      return;
    }

    const data = {
      username,
      email,
      password,
    };
    setUserData(data);
    setIsLoading(true);
    const code = await sendEmail(username, email);
    setConfirmation(code);
    setIsConfirmed(true);
    setIsLoading(false);
  };

  const sendEmail = async (username, email) => {
    try {
      const res = await axios.post(
        `${BACKEND_PORT}/api/users/confirm`,
        {
          username,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Confirmation code sent");
      //console.log(res.data);
      return res.data;
    } catch (err) {
      toast.error(`${err.response.data.message} ${err.response.status}`);
      console.log(err.response.data.message, err.response.status);
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();

    if (e.target[0].value !== confirmation) {
      toast.error("Invalid Code!");
      return;
    }
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_PORT}/api/users/Register`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (res.status === 200) {
        navigate("/login");
        toast.success("Registered Successfully");
      } else if (res.status === 201) {
        toast.error("User already exists");
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(`${err.response.data.message} ${err.response.status}`);
      console.log(err.response.data.message, err.response.status);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      {/* <button onClick={() => setIsConfirmed(!isConfirmed)}>here</button> */}
      <div className="flex pt-12 sm:items-center justify-center px-6 py-8 mx-auto h-[100dvh] lg:py-0">
        <div className="w-full sm:max-w-md">
          <div className="w-full flex justify-center">
            <Link
              to="/"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
              My GPT
            </Link>
          </div>

          {!isConfirmed ? (
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
                  Register
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={toConfirm}>
                  <div>
                    <label
                      htmlFor="username"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@company.com"
                      required=""
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@company.com"
                      required=""
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="confirm-password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="confirm-password"
                      name="confirm-password"
                      id="confirm-password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                  </div>

                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500 dark:text-gray-300"
                      >
                        I accept the{" "}
                        <Link
                          className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                          to="#"
                        >
                          Terms and Conditions
                        </Link>
                      </label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    {isLoading ? <Spinner /> : "Submit"}
                  </button>

                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                      Login
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          ) : (
            <Confirm
              isLoading={isLoading}
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              handleSubmit={registerUser}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Register;
