import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { truncateText } from "../utils/format";
import SidebarSkeleton from "./pulse/sidebarSkeleton";

const Sidebar = ({
  hideSidebar,
  setHideSidebar,
  data,
  rowId,
  setRowId,
  loading,
  navSidebarRef,
  sidebarRef,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHides = (id) => {
    setRowId(id);
    setHideSidebar(true);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      if (
        navSidebarRef.current &&
        !navSidebarRef.current.contains(event.target)
      ) {
        setHideSidebar(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <aside
        className={`fixed top-0 left-0 z-40 h-screen pt-20 transition-transform bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700 w-64 ${
          hideSidebar && "-translate-x-full"
        }`}
        ref={sidebarRef}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-normal">
            <li>
              <span className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white group relative">
                <span className="ms-3">Chats</span>
                <span>
                  <FiEdit
                    className="cursor-pointer"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => handleHides(null)}
                  />
                  <span
                    className={`opacity-0 absolute text-xs -right-2 top-10 z-[999] ${
                      isHovered && "opacity-100"
                    }`}
                  >
                    <p className="px-1 py-2 bg-gray-100 dark:bg-gray-800 border border-gray-200  dark:border-gray-700 rounded-[.3rem]">
                      New chat
                    </p>
                  </span>
                </span>
              </span>
            </li>
            {data && !loading
              ? data.map((item) => (
                  <li key={item.id}>
                    <div
                      onClick={() => handleHides(item.id)}
                      className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group cursor-pointer font-light text-sm ${
                        item.id === rowId && "bg-gray-100 dark:bg-gray-700"
                      }`}
                    >
                      <span className="flex-1 ms-3">
                        <span>{truncateText(item.text, 20)}</span>
                        {/* {item.text.length > 20 && (
                        <span className="absolute left-0 top-4 w-auto px-2 py-1 text-xs text-white bg-black rounded opacity-100 group-hover:opacity-100 whitespace-nowrap z-[100]">
                          {item.text}
                        </span>
                      )} */}
                      </span>
                    </div>
                  </li>
                ))
              : loading && <SidebarSkeleton />}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
