import { configureStore } from "@reduxjs/toolkit";
import conversationSlice from "./messages/conversationSlice";
import messagesSlice from "./messages/messagesSlice";
import generateAIResponse from "./gen/generateResponseSlice";
import loginSlice from "./auth/loginSlice";
import logoutSlice from "./auth/logoutSlice";

const store = configureStore({
  reducer: {
    login: loginSlice,
    logout: logoutSlice,
    con: conversationSlice,
    messages: messagesSlice,
    MY_GPT: generateAIResponse,
  },
});

export default store;
