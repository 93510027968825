import React, { useEffect, useRef, useState } from "react";
import logo from "./img/logo.png";
import { FaRegUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, resetLogoutState } from "../redux/auth/logoutSlice";
import toast from "react-hot-toast";
import { resetGetConversation } from "../redux/messages/conversationSlice";

const Nav = ({
  setHideSidebar,
  hideSidebar,
  setIsOpen,
  isOpen,
  navSidebarRef,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileRef = useRef();
  const { success } = useSelector((state) => state.logout);
  const [hideProfile, setHideProfile] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setHideProfile(true);
  };

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logout());
    toast.loading("Logging out...");
  };

  useEffect(() => {
    if (success) {
      toast.dismiss();
      localStorage.removeItem("user");
      dispatch(resetLogoutState());
      dispatch(resetGetConversation());
      navigate("/login");
    }
  }, [success, dispatch, navigate]);

  const toggleProfile = (e) => {
    e.stopPropagation();
    setHideProfile(!hideProfile);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setHideProfile(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => setHideSidebar(!hideSidebar)}
                ref={navSidebarRef}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 md:hidden"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="/" className="flex ms-2 md:me-24">
                <img src={logo} className="h-8 me-3" alt="FlowBite Logo" />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-gray-600 dark:text-white">
                  My GPT
                </span>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3 relative" ref={profileRef}>
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                    onClick={toggleProfile}
                  >
                    <span className="sr-only">Open user menu</span>
                    {!user?.photoUrl ? (
                      <FaRegUserCircle className="text-gray-600 dark:text-zinc-300 text-3xl bg-white dark:bg-gray-800" />
                    ) : (
                      <img
                        className="w-8 h-8 rounded-full"
                        src={user?.photoUrl}
                        alt="user"
                      />
                    )}
                  </button>
                </div>
                <div
                  className={`z-50 absolute top-4 right-0 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 min-w-48 ${
                    hideProfile && "hidden"
                  }`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none"
                    >
                      {user?.username}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none"
                    >
                      {user?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                        onClick={handleOpen}
                      >
                        Settings
                      </a>
                    </li>

                    <li>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                        onClick={signOut}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
