import Spinner from "../components/spinner/spinner";

const Confirm = ({ isLoading, handleSubmit }) => {
  return (
    <section className="w-full sm:max-w-md bg-gray-50 dark:bg-gray-900 flex justify-center">
      <form
        className="max-w-sm w-full mx-auto pt-12 sm:pt-0 sm:pb-80"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            htmlFor="code"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Size altı haneli bir kod içeren onay e-postası gönderdik. Lütfen
            kodu giriniz.
          </label>
          <input
            type="text"
            name="code"
            id="code"
            placeholder="000000"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            required
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          {isLoading ? <Spinner /> : "Devam"}
        </button>
      </form>
    </section>
  );
};

export default Confirm;
