export function getTimeAndMinutes(dateTimeString) {
  const date = new Date(dateTimeString);

  // Get the hours and minutes
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Return the time in "HH:MM" format
  return `${hours}:${minutes}`;
}

export function getCurrentTime() {
  const now = new Date();

  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");

  const data = `${hours}:${minutes}`;
  return data;
}
