import logo from "../img/logo.png";
import "./pulse.css";

const LoadingSpinner = () => {
  return (
    <div className={`flex gap-2.5`}>
      <img className="w-8 h-8 rounded-full" src={logo} alt="gpt_logo" />
      <div className={`flex flex-col gap-1 w-full max-w-2xl `}>
        <div className={`flex items-center `}>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            ...
          </span>
        </div>
        <div
          className={`flex flex-col leading-1.5 p-4 border-gray-200  rounded-ee-xl rounded-es-xl  "rounded-se-xl text-gray-500 text-md"
        `}
        >
          <div className="w-8 h-8 flex justify-center items-center">
            <div className="typing-animation bg-slate-950 dark:bg-slate-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
