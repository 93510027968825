import Search from "./search";
import Landing from "./landing";
import ChatCard from "./chatCard";
import toast from "react-hot-toast";
import LoadingSpinner from "./pulse/pulse";
import ChatSkeleton from "./pulse/chatSkeleton";
import { useEffect, useState, useRef } from "react";
import { getCurrentTime } from "../utils/formatDate";
import { useDispatch, useSelector } from "react-redux";

///*******Slices *******///
import {
  postConversation,
  resetPostSuccess,
} from "../redux/messages/conversationSlice";
import {
  getMessages,
  postMessages,
  resetMessages,
} from "../redux/messages/messagesSlice";
import {
  checkGenerateLimit,
  generateAIResponse,
  resetAIResponse,
  resetGenerateLimit,
} from "../redux/gen/generateResponseSlice";

const Chat = ({
  rowId,
  setRowId,
  conversations,
  setConversations,
  newChat,
  setNewChat,
}) => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.messages);
  const { success, data: row_id } = useSelector((state) => state.con.post);
  const { aiSucc, aiErr, aiData } = useSelector((state) => state.MY_GPT);
  const { success: genSuccess, max } = useSelector(
    (state) => state.MY_GPT.generateLimit
  );

  const chatContainerRef = useRef(null); /**For auto acroll**/

  const [chat, setChat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [inputDisplay, setInputDisplay] = useState("");
  const [displayedText, setDisplayedText] = useState("");

  const handleSearch = async () => {
    if (!searchInput) return;
    setIsLoading(true);
    setInputDisplay("");
    updateChat(searchInput, "user");
    dispatch(checkGenerateLimit());
  };

  //SIMPLE FUNCTIONS
  const updateChat = async (message, type) => {
    const time = getCurrentTime();
    const updatedChat = [...newChat];
    const i = updatedChat.length - 1;

    if (updatedChat[i]?.type === type) {
      updatedChat.pop();
    }

    updatedChat.push({
      message,
      type,
      created_at: time,
      status: "new",
    });
    setNewChat(updatedChat);
  };

  const updateConv = async (conv) => {
    const updatedConv = [...conversations];
    updatedConv.push({
      id: "temp_id",
      user_id: null,
      created_at: null,
      text: conv,
    });
    setConversations(updatedConv);
  };

  // USEEFFECTS
  useEffect(() => {
    const continueSearch = () => {
      try {
        if (!rowId) {
          setRowId("temp_id");
          updateConv(searchInput);
          dispatch(postConversation(searchInput));
        }

        //Start Generating Rsponse
        if (rowId && rowId !== "temp_id") {
          dispatch(postMessages({ id: rowId, txt: searchInput, type: "user" }));
          setSearchInput("");
        } else if (row_id) {
          dispatch(
            postMessages({ id: row_id, txt: searchInput, type: "user" })
          );
          setSearchInput("");
        }
        dispatch(generateAIResponse(searchInput));
        //const res = mockReq()
      } catch (err) {
        console.log("Error fetching chat", err);
        setIsLoading(false);
      }
    };

    if (genSuccess) {
      if (!max) {
        continueSearch();
        dispatch(resetGenerateLimit());
      } else {
        setIsLoading(false);
        dispatch(resetGenerateLimit());
        toast.error("You have reached the maximum generate limit");
      }
    }
  }, [max, genSuccess]);

  useEffect(() => {
    if (aiSucc && aiData) {
      updateChat(aiData, "gpt");
      if (rowId !== "temp_id") {
        dispatch(postMessages({ id: rowId, txt: aiData, type: "gpt" }));
      } else if (row_id) {
        dispatch(postMessages({ id: row_id, txt: aiData, type: "gpt" }));
      } else {
        toast.error("Cannot put gpt response, Not row Id found!");
      }
      dispatch(resetAIResponse());
      setIsLoading(false);
    }
    if (aiErr) {
      setIsLoading(false);
      toast.error(aiErr);
      dispatch(resetAIResponse());
    }
  }, [aiSucc, aiData, dispatch, rowId, aiErr, searchInput, row_id]);

  useEffect(() => {
    if (rowId && rowId !== "temp_id") {
      dispatch(getMessages(rowId));
    } else {
      setChat([]);
    }
  }, [rowId, dispatch]);

  useEffect(() => {
    if (data) {
      setChat(data);
      dispatch(resetMessages());
    }
  }, [data, rowId, dispatch]);

  useEffect(() => {
    if (success && searchInput) {
      dispatch(postMessages({ id: row_id, txt: searchInput, type: "user" }));
      dispatch(resetPostSuccess());
      setSearchInput("");
    }
  }, [success, dispatch, searchInput]);

  // scroll to the bottom
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat, rowId, displayedText]);

  return (
    <>
      <section className="h-full md:ml-64 pt-14 flex flex-col justify-between items-center bg-gray-50 dark:bg-gray-900 relative">
        <div
          className="w-full flex justify-center mb-32 overflow-y-scroll"
          ref={chatContainerRef}
        >
          <div className="w-full max-w-3xl p-4 flex flex-col gap-4 sm:pr-8">
            {chat ? (
              <>
                {!loading &&
                  chat.map((item, i) => (
                    <ChatCard
                      i={i}
                      key={i}
                      chat={newChat}
                      item={item}
                      rowId={rowId}
                      displayedText={displayedText}
                      setDisplayedText={setDisplayedText}
                    />
                  ))}
                {newChat &&
                  newChat.map((item, i) => (
                    <ChatCard
                      i={i}
                      key={i}
                      chat={newChat}
                      item={item}
                      rowId={rowId}
                      displayedText={displayedText}
                      setDisplayedText={setDisplayedText}
                    />
                  ))}
                {isLoading && <LoadingSpinner />}
                {loading && rowId !== "temp_id" && <ChatSkeleton />}
                <div className="min-h-24 w-1"></div>
              </>
            ) : (
              <Landing />
            )}
          </div>
        </div>
        <Search
          handleSearch={handleSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          isLoading={isLoading}
          inputDisplay={inputDisplay}
          setInputDisplay={setInputDisplay}
        />
      </section>
    </>
  );
};

export default Chat;
