import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";

const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  aiLoad: false,
  aiSucc: false,
  aiErr: null,
  aiData: null,
  generateLimit: {
    success: false,
    max: false,
  },
};

const generateResponseSlice = createSlice({
  name: "AIresponse",
  initialState: initialState,
  reducers: {
    resetAIResponse: (state) => {
      state.aiLoad = false;
      state.aiSucc = false;
      state.aiErr = null;
      state.aiData = null;
    },
    resetGenerateLimit: (state) => {
      state.generateLimit = {
        ...state.generateLimit,
        success: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateAIResponse.pending, (state) => {
        state.aiLoad = true;
        state.aiSucc = false;
        state.aiErr = null;
        state.aiData = null;
      })
      .addCase(generateAIResponse.fulfilled, (state, action) => {
        state.aiLoad = false;
        state.aiSucc = true;
        state.aiErr = null;
        state.aiData = action.payload;
      })
      .addCase(generateAIResponse.rejected, (state, action) => {
        state.aiLoad = false;
        state.aiSucc = false;
        state.aiErr = action.error.message;
        state.aiData = null;
      })
      .addCase(checkGenerateLimit.fulfilled, (state, action) => {
        state.generateLimit = {
          success: true,
          max: action.payload,
        };
      });
  },
});

export const generateAIResponse = createAsyncThunk(
  "data/generateAIResponse",
  async (prompt) => {
    try {
      const token = JSON.parse(localStorage.getItem("user"))?.token;
      const res = await axios.get(`${BACKEND_PORT}/api/data/generateGPT`, {
        params: {
          prompt,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      //console.log(err.response.data, "Error generating");
      if (err.response.status === 400) {
        const error = new Error(err.response.data.message);
        throw error;
      } else throw err;
    }
  }
);

export const checkGenerateLimit = createAsyncThunk(
  "api/data/generate-limit",
  async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user"))?.token;
      const res = await axios.get(
        `${BACKEND_PORT}/api/data/checkGenerateLimit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }
);

export default generateResponseSlice.reducer;
export const { resetAIResponse, resetGenerateLimit } =
  generateResponseSlice.actions;
