/* import { useEffect } from "react";

const TypingEffect = ({ message, displayedText, setDisplayedText }) => {
  useEffect(() => {
    if (!message) return;

    setDisplayedText("");

    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => {
        const newText = prev + message[index];
        index += 1;
        if (index >= message.length) {
          clearInterval(interval);
        }
        return newText;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [message, setDisplayedText]);

  return <p className="font-normal">{displayedText}</p>;
};

export default TypingEffect;
 */

import { useEffect, useState } from "react";

const TypingEffect = ({ message, displayedText, setDisplayedText }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isList, setIsList] = useState(false);

  useEffect(() => {
    if (!message) return;

    setDisplayedText("");
    setCurrentIndex(0);

    const isListMessage = message.match(/(\d+\.\s|\-\s|\*\s)/);
    setIsList(isListMessage);

    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => {
        const newText = prev + message[index];
        index += 1;
        if (index >= message.length) {
          clearInterval(interval);
        }
        return newText;
      });
      setCurrentIndex(index);
    }, 10);

    return () => clearInterval(interval);
  }, [message, setDisplayedText]);

  const renderLine = (line, index) => {
    return <li key={index}>{line}</li>;
  };

  return (
    <>
      {isList ? (
        <ul className="list-inside">
          {message
            .slice(0, currentIndex)
            .split("\n")
            .map((line, index) => renderLine(line, index))}
        </ul>
      ) : (
        <p className="font-normal">{displayedText}</p>
      )}
    </>
  );
};

export default TypingEffect;
