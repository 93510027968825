import { useEffect, useRef, useState } from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sidebar";
import Chat from "../components/chat";
import Profile from "../components/profile";
import { useDispatch, useSelector } from "react-redux";
import { getConversations } from "../redux/messages/conversationSlice";
import { resetPostConversation } from "../redux/messages/conversationSlice";
import { logout } from "../redux/auth/logoutSlice";

const Main = () => {
  const dispatch = useDispatch();
  const { loading, success, err, data } = useSelector((state) => state.con.get);
  const [hideSidebar, setHideSidebar] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [conversations, setConversations] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [newChat, setNewChat] = useState([]);
  const navSidebarRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (rowId !== "temp_id") {
      setNewChat([]);
      dispatch(getConversations());
      dispatch(resetPostConversation());
    }
  }, [dispatch, rowId]);

  useEffect(() => {
    if (success) {
      setConversations(data);
    } else if (err?.code === 401) {
      dispatch(logout());
    }
  }, [success, data, err?.code, dispatch]);

  return (
    <>
      <Nav
        setHideSidebar={setHideSidebar}
        hideSidebar={hideSidebar}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        navSidebarRef={navSidebarRef}
      />
      <Sidebar
        hideSidebar={hideSidebar}
        setHideSidebar={setHideSidebar}
        data={conversations}
        rowId={rowId}
        setRowId={setRowId}
        loading={loading}
        navSidebarRef={navSidebarRef}
        sidebarRef={sidebarRef}
      />
      <Chat
        rowId={rowId}
        setRowId={setRowId}
        newChat={newChat}
        setNewChat={setNewChat}
        conversations={conversations}
        setConversations={setConversations}
      />
      <Profile isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Main;
