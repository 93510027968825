import { getTimeAndMinutes } from "../utils/formatDate";
import logo from "./img/logo.png";
import TypingEffect from "./typingEffect";
const ChatCard = ({
  item,
  i,
  chat,
  displayedText,
  setDisplayedText,
  rowId,
}) => {
  const mimicTxt = () => {
    if (
      chat.length === i + 1 &&
      item.type === "gpt" &&
      item?.status === "new"
    ) {
      return true;
    } else return false;
  };

  const renderMessage = (message) => {
    const isList = message.match(/(\d+\.\s|\-\s|\*\s)/);

    if (isList) {
      // Split the message into list items and render as <li>
      const listItems = message
        .split("\n")
        .map((item, index) => <li key={index}>{item}</li>);
      return <ul className="list-inside">{listItems}</ul>;
    } else {
      return <p className="font-normal">{message}</p>;
    }
  };

  return (
    <>
      <div className={`flex odd:self-end odd:items-end gap-2.5`} key={item.id}>
        {item.type === "gpt" && (
          <img className="w-8 h-8 rounded-full" src={logo} alt="gpt_logo" />
        )}
        <div
          className={`flex flex-col gap-1 w-full max-w-2xl ${
            i % 2 === 0 && "max-w-[320px]"
          }`}
        >
          <div className={`flex items-center ${i % 2 === 0 && "justify-end"}`}>
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {item.created_at.length > 6
                ? getTimeAndMinutes(item.created_at)
                : item.created_at}
            </span>
          </div>
          <div
            className={`flex flex-col leading-1.5 p-4 border-gray-200  rounded-ee-xl rounded-es-xl text-gray-500 ${
              i % 2 === 0
                ? "rounded-ss-xl bg-gray-100 dark:bg-gray-700  dark:text-gray-50 text-sm"
                : "rounded-se-xl text-md pl-0 sm:pl-4"
            }`}
          >
            {mimicTxt() ? (
              <TypingEffect
                message={item.message}
                displayedText={displayedText}
                setDisplayedText={setDisplayedText}
              />
            ) : (
              renderMessage(item.message)
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatCard;
