import React from "react";

const SidebarSkeleton = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
        <li key={i}>
          <div className={`flex items-center rounded-lg cursor-pointer fade`}>
            <div className="w-full">
              <div className="h-8 bg-gray-100 dark:bg-gray-700 rounded-lg w-full max-w-56 mb-2"></div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

export default SidebarSkeleton;
