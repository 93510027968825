import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
};

const messageSlice = createSlice({
  name: "messages",
  initialState: initialState,
  reducers: {
    resetMessages: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.data = null;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.data = null;
      });
  },
});

export const getMessages = createAsyncThunk("data/getMessages", async (id) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    const res = await axios.get(`${BACKEND_PORT}/api/data/messages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });

    //console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error fetching chat", err);
    toast.dismiss();
    toast.error(err.message);
    return [];
  }
});

export const postMessages = createAsyncThunk(
  "data/postMessage",
  async ({ id: conversation_id, txt: message, type }) => {
    try {
      const token = JSON.parse(localStorage.getItem("user"))?.token;
      await axios.post(
        `${BACKEND_PORT}/api/data/messages`,
        {
          conversation_id,
          message,
          type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log("Error", err.message);
      toast.error(err.message);
      throw err;
    }
  }
);

export default messageSlice.reducer;
export const { resetMessages } = messageSlice.actions;
