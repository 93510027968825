const ChatSkeleton = () => {
  return (
    <>
      {[0, 1, 2, 3, 4].map((i) => (
        <div key={i} className={`flex odd:self-end odd:items-end gap-2.5 fade`}>
          <div
            className={`flex flex-col gap-1 w-full max-w-2xl ${
              i % 2 === 0 && "max-w-[320px]"
            }`}
          >
            <div
              className={`flex items-center ${i % 2 === 0 && "justify-end"}`}
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400"></span>
            </div>
            <div
              className={`flex flex-col leading-1.5 p-4 border-gray-200  rounded-ee-xl rounded-es-xl text-gray-500 ${
                i % 2 === 0
                  ? "rounded-ss-xl bg-gray-100 dark:bg-gray-700  dark:text-gray-50 text-sm"
                  : "rounded-se-xl text-md"
              }`}
            >
              {i % 2 === 0 ? (
                <p className="font-normal w-48 h-6"></p>
              ) : (
                <>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ChatSkeleton;
