import { useEffect, useRef } from "react";
import { FaArrowUp } from "react-icons/fa6";
import { FaStop } from "react-icons/fa6";

const Search = ({
  handleSearch: search,
  setSearchInput,
  searchInput,
  isLoading,
  inputDisplay,
  setInputDisplay,
}) => {
  const textareaRef = useRef();

  const handleInput = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set to scrollHeight
  };

  const handleKeyDown = (e) => {
    if (!e.target.value) return;
    if (e.key === "Enter") {
      e.preventDefault();
      search();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    search();
  };

  useEffect(() => {
    if (!searchInput) {
      if (textareaRef?.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  }, [searchInput]);

  useEffect(() => {
    setInputDisplay(searchInput);
  }, [searchInput]);

  return (
    <div className="w-full fixed bottom-0 flex bg-gray-50 dark:bg-gray-900">
      <div className="w-full flex justify-center mb-8">
        <div className="w-full max-w-3xl px-4">
          <form
            className="flex bg-gray-50 rounded-[1.8rem] border border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 px-2 pl-8 overflow-clip"
            onSubmit={handleSearch}
          >
            <div className="relative w-full">
              <textarea
                type="text"
                className=" text-sm bg-gray-50 text-gray-900 dark:bg-gray-700 block w-full dark:placeholder-gray-400 dark:text-white focus:outline-none pl-2 pt-4 max-h-[25dvh] overflow-y-auto resize-none h-[3rem]"
                placeholder="Message my GPT..."
                required
                value={inputDisplay}
                onInput={handleInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
                ref={textareaRef}
              />
            </div>
            <div className="flex items-end">
              <div className="flex items-center h-[3.5rem]">
                <button
                  disabled={isLoading}
                  className={`text-lg font-medium text-white bg-blue-700 rounded-full border border-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 p-2`}
                >
                  {isLoading ? <FaStop /> : <FaArrowUp />}
                  <span className="sr-only">Search</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Search;
