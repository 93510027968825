const Landing = () => {
  return (
    <div className="w-full max-w-3xl p-4 flex flex-col gap-4 sm:pr-8">
      <div>
        <div className="flex items-center justify-center pt-36">
          <span className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Noteworthy technology acquisitions 2021
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Landing;
