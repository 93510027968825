import { Route, Routes } from "react-router-dom";
import { ChatProvider } from "./context/chatContext";
import { Toaster } from "react-hot-toast";

///***********PAGES *********///
import Login from "./pages/login";
import Register from "./pages/register";
import Main from "./pages/main";
import Protect from "./components/protect";
import NotFound from "./pages/404";

function App() {
  return (
    <>
      <ChatProvider>
        <Routes>
          <Route element={<Protect />}>
            <Route path="/" element={<Main />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster position="top-center" reverseOrder={false} />
      </ChatProvider>
    </>
  );
}

export default App;
