import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  get: {
    loading: false,
    success: false,
    err: null,
    data: null,
  },
  post: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialState,
  reducers: {
    resetPostConversation: (state) => {
      state.post = {
        loading: false,
        success: false,
        error: null,
        data: null,
      };
    },
    resetPostSuccess: (state) => {
      state.post = {
        ...state.post,
        success: false,
      };
    },
    resetGetConversation: (state) => {
      state.get = {
        loading: false,
        success: false,
        err: null,
        data: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.pending, (state) => {
        state.get = {
          loading: true,
          success: false,
          err: null,
          data: null,
        };
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.get = {
          loading: false,
          success: true,
          err: null,
          data: action.payload,
        };
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.get = {
          loading: false,
          success: false,
          err: action.payload,
          data: null,
        };
      })
      .addCase(postConversation.pending, (state) => {
        state.post = {
          loading: true,
          success: false,
          error: null,
          data: null,
        };
      })
      .addCase(postConversation.fulfilled, (state, action) => {
        state.post = {
          loading: false,
          success: true,
          error: null,
          data: action.payload,
        };
      })
      .addCase(postConversation.rejected, (state, action) => {
        state.post = {
          loading: false,
          success: false,
          error: action.error,
          data: null,
        };
      });
  },
});

export const getConversations = createAsyncThunk(
  "data/getConversations",
  async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    try {
      const res = await axios.get(`${BACKEND_PORT}/api/data/conversation`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      //console.log("Error fetching chat", err);
      toast.dismiss();
      toast.error(err.message);
      return rejectWithValue({
        message: err.message,
        code: err.response.status,
      });
    }
  }
);

export const postConversation = createAsyncThunk(
  "data/postConversation",
  async (conversation) => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    try {
      const res = await axios.post(
        `${BACKEND_PORT}/api/data/conversation`,
        { conversation },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log(res.data);
      return res.data;
    } catch (err) {
      console.log("Error", err.response.data.message, err.message);
      throw err;
    }
  }
);

export default conversationSlice.reducer;
export const { resetPostConversation, resetPostSuccess, resetGetConversation } =
  conversationSlice.actions;
