import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  loading: false,
  success: false,
  error: null,
  user: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    resetLoginState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.user = null;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.user = null;
      });
  },
});

export const login = createAsyncThunk(
  "api/users/login",
  async ({ email, password }) => {
    try {
      const res = await axios.post(
        `${BACKEND_PORT}/api/users/login`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("user", JSON.stringify(res.data));
      return res.data;
    } catch (err) {
      if (err.response.data) {
        //console.log(err.response.data);
        throw err.response.data;
      } else throw err.message;
    }
  }
);

export default loginSlice.reducer;
export const { resetLoginState } = loginSlice.actions;
