import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: null,
  user: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState: initialState,
  reducers: {
    resetLogoutState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.user = null;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
        state.user = null;
      });
  },
});

export const logout = createAsyncThunk("api/users/logout", async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return null;
});

export default logoutSlice.reducer;
export const { resetLogoutState } = logoutSlice.actions;
